import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../scss/style.css";

const Header = () => {
  const [activeLink, setActiveLink] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const scrollToHome = () => {
      const homeSection = document.getElementById("Home");
      if (homeSection) {
        homeSection.scrollIntoView({ behavior: "smooth" });
      }
    };

    scrollToHome();

    const handleScroll = () => {
      const sections = document.querySelectorAll("section[id]");
      const scrollY = window.scrollY;

      sections.forEach((section) => {
        const sectionTop = section.offsetTop;
        const sectionHeight = section.offsetHeight;

        if (
          scrollY >= sectionTop - 50 &&
          scrollY <= sectionTop + sectionHeight - 50
        ) {
          setActiveLink(section.id);
        }
      });
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleClick = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleNavigateToHome = () => {
    navigate("/");
    window.location.reload(); // Refresh the page
  };

  return (
    <nav className="navbar navbar-expand-sm bg-dark navbar-dark fixed-top">
      <div className="container-fluid">
        <Link className="navbar-brand" to="/" onClick={handleNavigateToHome}>
          DataEaver
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarCollapse">
          <ul className="navbar-nav ms-auto mb-2 mb-md-0">
            <li className="nav-item">
              <Link
                className={`nav-link ${
                  activeLink === "Services" ? "active" : ""
                }`}
                to="/#Services"
                onClick={() => handleClick("Services")}
              >
                Services
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`nav-link ${
                  activeLink === "Solutions" ? "active" : ""
                }`}
                to="/#Solutions"
                onClick={() => handleClick("Solutions")}
              >
                Solutions
              </Link>
            </li>
            {/* <li className="nav-item">
              <Link
                className={`nav-link ${
                  activeLink === "Job_search" ? "active" : ""
                }`}
                to="/#Job_search"
                onClick={() => handleClick("Job_search")}
              >
                Jobs Search
              </Link>
            </li> */}
            <li className="nav-item">
              <Link
                className={`nav-link ${
                  activeLink === "About" ? "active" : ""
                }`}
                to="/#About"
                onClick={() => handleClick("About")}
              >
                About Us
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`nav-link ${
                  activeLink === "Contact" ? "active" : ""
                }`}
                to="/#Contact"
                onClick={() => handleClick("Contact")}
              >
                Contact
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Header;
