import React, { useState, useEffect } from 'react';
import PdfViewer from './PdfViewer';
import Header from './Header';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext.js';
import axiosInstance from '../context/axios-interceptor.js';


const Job_Application_Detail = () => {

  const [jobApplications, setJobApplications] = useState([]);
  const [selectedResume, setSelectedResume] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  const totalPages = Math.ceil(jobApplications.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const visibleApplications = jobApplications.slice(startIndex, endIndex);

  const navigate = useNavigate();
  
  const { id } = useAuth();

  useEffect(() => {
      if (id === '') {
          navigate('/Login')
      }
      else {
          console.log(id);
      }
  }, [id]);

  const changePage = (newPage) => {
    setCurrentPage(newPage);
  };


  const viewResume = (resumePath) => {
    if (resumePath) {
      const cleanFilePath = `pdfupload/${resumePath.replace(/^pdfupload\//, '')}`;
      setSelectedResume(cleanFilePath);
    }
  };

  useEffect(() => {
    const fetchJobApplications = async () => {
      try {
        const response = await axiosInstance.get('get_job_applications');
        setJobApplications(response.data);
      } catch (error) {
        console.error('Error fetching job applications:', error);
      }
    };

    fetchJobApplications();
  }, []);

  return (
    <div>
      <Header></Header>
      <div className='p-3'></div>
      <h1 className='font-weight-bold text-center'>Job Application Details</h1>
      <div className='row'>
        <div className='col-sm-12'>
        <div class="container mt-3">
        <table className="table table table-hover">
          <thead>
            <tr>
              <th scope="col">Sr.no</th>
              <th>Name</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Resume Path</th>
              <th>option</th>
            </tr>
          </thead>
          {visibleApplications.map((application, index) => (
            <tbody key={application.id}>
              <tr>
              <th scope="row">{startIndex + index + 1}</th>
                <td> {`${application.firstName} ${application.lastName}`}</td>
                <td> {application.email}</td>
                <td> {application.phone}</td>
                <td> {application.resume}</td>
                <td>
                  <button className='btn btn-primary' onClick={() => viewResume(application.resume)}>View Resume</button>
                </td>
              </tr>
            </tbody>

          ))}
        </table>
        <nav className="text-center">
        <ul className="pagination justify-content-center">
          {Array.from({ length: totalPages }, (_, index) => (
            <li key={index + 1} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
              <button className="page-link" onClick={() => changePage(index + 1)}>
                {index + 1}
              </button>
            </li>
          ))}
        </ul>
      </nav>
        </div>
        </div>
      </div>
      {selectedResume && <PdfViewer filePath={selectedResume} />}
    </div>
  );
};

export default Job_Application_Detail;
