import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const PdfViewer = ({ filePath }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const handleDownload = () => {
    if (!filePath) {
      console.error('File path is undefined.');
      return;
    }

    const anchor = document.createElement('a');
    anchor.href = filePath;
    anchor.download = 'downloaded-pdf.pdf';

    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };

  const handleError = (error) => {
    console.error('Error loading PDF:', error);
    setError('Failed to load PDF. Please check the file path.');
    setLoading(false);
  };

  return (
    <div>
      {loading && <p>Loading...</p>}
      {error && <p style={{ color: 'red' }}>{error}</p>}
      <Document file={filePath} onLoadSuccess={() => setLoading(false)} onLoadError={handleError}>
        <Page pageNumber={1} />
      </Document>
      <button onClick={handleDownload} disabled={loading || error}>
        Download PDF
      </button>
    </div>
  );
};

export default PdfViewer;
