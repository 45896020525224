import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../adminpanel/Header';
import { useAuth } from '../context/AuthContext.js';
import axiosInstance from '../context/axios-interceptor.js';

function Add_job_posting() {

    const [formValid, setFormValid] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [job_posting_id, setjob_posting_id] = useState("");
    const [job_category, setjobCategory] = useState("");
    const [job_role, setjobRole] = useState("");
    const [job_type, setjobType] = useState("");
    const [job_location, setjobLocation] = useState("");
    const [job_description, setjobDescription] = useState("");
    const [job_status, setjobStatus] = useState("");
    const [job_lastdate, setjobLastDate] = useState("");
    const [editingRowId, setEditingRowId] = useState(null);
    let serialNumber = 1;
    const [jobs_posting, setjobs_posting] = useState([]);

    const navigate = useNavigate();
    const { id } = useAuth();

    useEffect(() => {
        if (id === '') {
            navigate('/Login')
        }
        else {
            console.log(id);
        }
    }, [id]);

    useEffect(() => {
    }, [navigate])


    const clearFields = () => {
        setjob_posting_id("");
        setjobCategory("");
        setjobRole("");
        setjobType("");
        setjobLocation("");
        setjobDescription("");
        setjobStatus(""); // Reset to default value
        setjobLastDate("");
    };

    useEffect(() => {
        if (
            job_category &&
            job_role &&
            job_type &&
            job_location &&
            job_description &&
            job_status &&
            job_lastdate
        ) {
            setFormValid(true);
        } else {
            setFormValid(false);
        }
    }, [
        job_category,
        job_role,
        job_type,
        job_location,
        job_description,
        job_status,
        job_lastdate,
    ]);

    const handleSubmit = (event) => {
        event.preventDefault();
        if (formValid) {
            Add_jobposting(event);
        }
        else {
            alert("Please fill in all required fields.");
        }
    };

    async function Add_jobposting(event) {
        event.preventDefault();
        //const secretKey = 'GHYZXFZH2A4B6N6P7R9SPNMT';
        const job_result = await axiosInstance.post("insert", {
            job_category: job_category,
            job_role: job_role,
            job_type: job_type,
            job_location: job_location,
            job_description: job_description,
            job_status: job_status,
            job_lastdate: job_lastdate
        });

        if (job_result.data.status) {
            alert(job_result.data.message);
            //navigate('/JobCardList')
            await fetchData();
            clearFields();
        } else {
            alert(job_result.data.message);
        }
    }

    useEffect(() => {
        (async () => await fetchData())();
    }, []);

    async function fetchData() {
        try {
            const response = await axiosInstance.get('jobdata'); // Replace with your API endpoint
            if (response.status === 200) {
                setjobs_posting(response.data.data);
            } else {
                console.error('Failed to fetch data');
            }
        } catch (error) {
            console.error('Error fetching job data:', error);
        }
    }

    useEffect(() => {
        (async () => await Load())();
    }, []);

    async function Load() {
        const display_result = await axiosInstance.get("jobdata", {
        });
        if (display_result.data.status) {
            setjobs_posting(display_result.data.data);
        }

    }

    async function editjob_posting(jobs_posting) {

        if (editingRowId && editingRowId !== jobs_posting.job_posting_id) {
            setIsEditing((prevIsEditing) => ({
                ...prevIsEditing,
                [editingRowId]: false,
            }));
        }

        setIsEditing((prevIsEditing) => ({
            ...prevIsEditing,
            [jobs_posting.job_posting_id]: !prevIsEditing[jobs_posting.job_posting_id],
        }));

        setEditingRowId(jobs_posting.job_posting_id);

        setjobCategory(jobs_posting.job_category);
        setjobRole(jobs_posting.job_role);
        setjobType(jobs_posting.job_type);
        setjobLocation(jobs_posting.job_location);
        setjobDescription(jobs_posting.job_description);
        setjobStatus(jobs_posting.job_status);
        setjobLastDate(jobs_posting.job_lastdate);
        setjob_posting_id(jobs_posting.job_posting_id);

    }

    const confirmDelete = (job_posting_id) => {
        const shouldDelete = window.confirm('Are you sure you want to delete this job posting?');

        if (shouldDelete) {
            Deletejob_posting(job_posting_id);
        }
    };

    async function Deletejob_posting(job_posting_id) {

        const delete_result = await axiosInstance.delete('delete/' + job_posting_id);
        if (delete_result.data.status) {
            alert(delete_result.data.message);
            Load();
        } else {
            alert(delete_result.data.message);
        }

    }

    async function update(event) {
        event.preventDefault();

        try {
            let update_result = await axiosInstance.put("update/" + jobs_posting.find(j => j.job_posting_id === job_posting_id).job_posting_id || job_posting_id,
                {

                    job_posting_id: job_posting_id,
                    job_category: job_category,
                    job_role: job_role,
                    job_type: job_type,
                    job_location: job_location,
                    job_description: job_description,
                    job_status: job_status,
                    job_lastdate: job_lastdate

                });
            if (update_result.data.status) {
                alert(update_result.data.message);
                Load();
                clearFields();
                setIsEditing(false);
                await fetchData();
            } else {
                alert(update_result.data.message);
            }


        }
        catch (err) {
            alert(err);
        }
    }

    return (
        <div >
            <Header></Header>
            <div className="row p-5">
                <div className="col-sm-4">
                    <div class="container bg-light border">
                        <div>
                            <div className="d-flex justify-content-center">
                                <h1 className="display-6">Add Job Details</h1>
                            </div>
                            <hr></hr>
                            <form onSubmit={handleSubmit}>
                                <div className="mb-1">
                                    <input type="text" hidden="true" className="form-control" id="job_posting_id" value={job_posting_id} onChange={(event) => { setjob_posting_id(event.target.value); }} />
                                </div>
                                <div className="mb-1">
                                    <label for="jobCategory" className="form-label">Job Category</label>
                                    <input type="text" className="form-control" id="job_category" value={job_category} onChange={(event) => { setjobCategory(event.target.value); }} />
                                </div>
                                <div className="mb-1">
                                    <label for="jobRole" className="form-label">Job Role</label>
                                    <input type="text" className="form-control" id="job_role" value={job_role} onChange={(event) => { setjobRole(event.target.value); }} />
                                </div>
                                <div className="mb-1">
                                    <label for="jobType" className="form-label">Job Type</label>
                                    <input type="text" className="form-control" id="job_type" value={job_type} onChange={(event) => { setjobType(event.target.value); }} />
                                </div>
                                <div className="mb-1">
                                    <label for="jobLocation" className="form-label">Job Location</label>
                                    <input type="text" className="form-control" id="job_location" value={job_location} onChange={(event) => { setjobLocation(event.target.value); }} />
                                </div>
                                <div className="mb-1">
                                    <label for="jobDescription" className="form-label">Job Description</label>
                                    <textarea className="form-control" id="job_description" rows="4" value={job_description} onChange={(event) => { setjobDescription(event.target.value); }}> </textarea>
                                </div>
                                <div className="mb-1">
                                    <label for="jobStatus" className="form-label">Job Status</label>
                                    <select className="form-select" id="job_status" value={job_status} onChange={(event) => { setjobStatus(event.target.value); }}>
                                        <option value="select" select="true" disabled>Select</option>
                                        <option value="open">Open</option>
                                        <option value="closed">Closed</option>
                                    </select>
                                </div>
                                <div className="mb-2">
                                    <label for="jobLastDate" className="form-label">Job Last Date</label>
                                    <input type="date" className="form-control" id="job_lastdate" value={job_lastdate} onChange={(event) => { setjobLastDate(event.target.value); }} />
                                </div>
                                <div className="d-flex justify-content-end mb-2">
                                    {isEditing[job_posting_id] ? (
                                        <button type="button" className="btn btn-warning" onClick={update}>Update</button>
                                    ) : (
                                        <button type="submit" className="btn btn-primary">Submit</button>
                                    )}
                                </div>

                            </form>

                        </div>
                    </div>
                </div>
                <div className="col-sm-8">
                    <table className="table table table-hover table-responsive-sm">
                        <thead className="table-light">
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Job Category</th>
                                <th scope="col">Job Role</th>
                                <th scope="col">Job Location</th>
                                <th scope="col">Actions</th>
                            </tr>
                        </thead>
                        {jobs_posting.map(function fn(job_posting) {
                            return (
                                <tbody key={job_posting.job_posting_id}>
                                    <tr>
                                        <th scope="row">{serialNumber++}</th>
                                        <td>{job_posting.job_category}</td>
                                        <td>{job_posting.job_role}</td>
                                        <td>{job_posting.job_location}</td>
                                        <td>
                                            <button type="button" className="btn btn-primary me-2" onClick={() => {
                                                editjob_posting(job_posting); if (isEditing[job_posting.job_posting_id]) {
                                                    clearFields();
                                                }
                                            }}>
                                                {isEditing[job_posting.job_posting_id] ? 'Cancel Edit' : 'Edit'}
                                            </button>
                                            <button type="button" className="btn btn-danger" onClick={() => confirmDelete(job_posting.job_posting_id)}>Delete</button>
                                        </td>
                                    </tr>
                                </tbody>
                            );
                        })}
                    </table>
                </div>

            </div>
        </div>
    );
}
export default Add_job_posting;
