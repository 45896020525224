import React, { useState } from 'react';
import '../css/contact.css';
import axiosInstance from '../context/axios-interceptor.js';

function Contact() {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        subject: '',
        message: '',
    });

    const clearFields = () => {
        setFormData({
            firstName: '',
            lastName: '',
            email: '',
            subject: '',
            message: '',
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await axiosInstance.post("postsend_email", formData);
            if (response.data.status) {
                alert(response.data.message);
                clearFields();
            } else {
                alert(response.data.message);
            }
        } catch (error) {
            console.error('Error sending email:', error);
        }
    };

    return (
        <div>
            <div id="Contact">
                <section className="contact_us text-center">
                    <div className="content">
                        <div className="container">
                            <div className="p-5"></div>
                            <h2>Tell Us What You Feel</h2>
                            <h6>Feel Free To Contact Us Any Time</h6>
                            <div className="p-3"></div>
                            <form onSubmit={handleSubmit}>
                                <div className="row g-3">
                                    <div className="col-md-2"></div>
                                    <div className="col-md-4">
                                        <input type="text" className="form-control" id="firstName"
                                            placeholder="First Name" value={formData.firstName} onChange={(e) => setFormData({ ...formData, firstName: e.target.value })} required />
                                    </div>
                                    <div className="col-md-4">
                                        <input type="text" className="form-control" id="lastName"
                                            placeholder="Last Name" value={formData.lastName} onChange={(e) => setFormData({ ...formData, lastName: e.target.value })} required />
                                    </div>
                                    <div className="col-md-2"></div>

                                    <div className="col-md-2"></div>
                                    <div className="col-md-4">
                                        <input type="email" className="form-control" id="email"
                                            placeholder="Email" value={formData.email} onChange={(e) => setFormData({ ...formData, email: e.target.value })} required />
                                    </div>
                                    <div className="col-md-4">
                                        <input type="text" className="form-control" id="subject"
                                            placeholder="Subject" value={formData.subject} onChange={(e) => setFormData({ ...formData, subject: e.target.value })} />
                                    </div>
                                    <div className="col-md-2"></div>

                                    <div className="col-md-2"></div>
                                    <div className="col-8">
                                        <textarea className="form-control" id="your-message" rows="5"
                                            placeholder="Message" value={formData.message} onChange={(e) => setFormData({ ...formData, message: e.target.value })} required></textarea>
                                    </div>
                                    <div className="col-md-2"></div>

                                    <div className="col-md-2"></div>
                                    <div className="col-8">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <button type="submit" className="btn btn-dark w-100 fw-bold">Send</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-2"></div>
                                    </div>
                            </form>
                            <div className="p-5"></div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
}

export default Contact;
