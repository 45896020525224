import React from "react";
import '../css/about2.css';

function About() {
    return (
        <div>
            <div id="About" class="container-fluid" style={{backgroundColor: '#054161e5'}}>
                <section class="showcase f text-white">

                    <div class="content">
                        <h1>About us</h1>
                        <div class="row">
                            <div class="col-sm-2"></div>
                            <div class="col-sm-8">
                                <p class="lead text-center text-white">
                                    DataEaver is a leading IT Consulting firm committed to helping organizations harness the
                                    power of data to
                                    drive innovation, efficiency, and growth. With a deep understanding of diverse industries
                                    and a
                                    passion for
                                    technology, we specialize in delivering end-to-end solutions and services tailored to your
                                    specific
                                    needs
                                </p>
                            </div>
                            <div class="col-sm-2"></div>
                        </div>

                        <div class="p-2"></div>
                        <h4>Follow Me On Social Media</h4>

                        <div class="social">
                            <a href="https://twitter.com/" target="_blank"><i class="fa fa-twitter"></i></a>
                            <a href="https://facebook.com/" target="_blank"><i class="fa fa-facebook"></i></a>
                            <a href="https://github.com/" target="_blank"><i class="fa fa-github"></i></a>
                            <a href="https://www.linkedin.com/" target="_blank"><i class="fa fa-linkedin"></i></a>
                        </div>

                    </div>
                </section>
            </div>
        </div>
    );
}

export default About;