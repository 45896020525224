import React, { useState, useEffect } from 'react';
import '../css/job.css';
import '../js/job.js';
import { useHref } from 'react-router-dom';
import axiosInstance from '../context/axios-interceptor.js';

function Jobs_search() {

    const [jobData, setJobData] = useState([]);

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await axiosInstance.get('jobdata'); // Replace with your API endpoint
                if (response.status === 200) {
                    setJobData(response.data.data);
                } else {
                    console.error('Failed to fetch data');
                }
            } catch (error) {
                console.error('Error fetching job data:', error);
            }
        }

        fetchData();
    }, []);

    const sendMail = () => {
        const subject = "Job Application"; // Replace with your desired subject
        const instructions = "Please attach your resume to this email."; // Instructions for attaching a resume
        const formattedBody = `
          First Name: 
          Last Name: 
      
          Email: 
          Phone: 
          
          Github repo: http://
          Linkedin: http://
      
          What is your experience (years): 
          When are you available: 
    
          Attach Resume: ${instructions}
        `;
        const mailToLink = `mailto:dataeaver@gmail.com?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(formattedBody)}`;
        window.open(mailToLink);
    };
    return (
        <div>
            {/* <div id="Job_search"> */}
            {/* <div class="p-5"></div>
        <div class="p-5"></div>
        <h1 class="display-5 text-center"><b>There are no job openings available at this time.</b></h1>
        <div class="p-5"></div>
        <div class="p-5"></div>
        <div class="p-5"></div> */}
            {/* <div class="Jobs">
                    <div class="JobsCard">
                        <h1 class="JobsCard-Title">Software Developer</h1>
                        <h2 class="JobsCard-Image">
                            <i class="fa fa-code fa-3x"></i>
                        </h2>
                        <div class="JobsCard-JobDescription">
                            <p>Category:Tech</p>
                            <p>Location:Remote, Canada</p>
                            <p>Date:Sep 27, 2023</p>
                        </div>
                        <div class="Button"  onClick={(e) => readMore(e)}>Read more</div>
                    </div>

                    <div class="JobsCard">
                        <h1 class="JobsCard-Title">
                            Senior Project Engineer</h1>
                        <h2 class="JobsCard-Image">
                            <i class="fa fa-laptop fa-3x"></i>
                        </h2>
                        <div class="JobsCard-JobDescription">
                            <p>Category:Tech</p>
                            <p>Location:San Francisco, CA</p>
                            <p>Date:Sep 27, 2023</p>
                        </div>
                        <div class="Button" onClick={sendMail}>Apply</div>
                    </div>

                    <div class="JobsCard">
                        <h1 class="JobsCard-Title">
                            Product Designer</h1>
                        <h2 class="JobsCard-Image">
                            <i class="fa fa-book fa-3x"></i>
                        </h2>
                        <div class="JobsCard-JobDescription">
                            <p>Category:Tech</p>
                            <p>Location:Remote, Canada</p>
                            <p>Date:Sep 27, 2023</p>
                        </div>
                        <div class="Button" onClick={sendMail}>Apply</div>
                    </div>
                    <div class="Overlayer"></div>
                    <div class="p-2"></div>
                </div> */}
            <div id="Job_search" class="container-fluid text-secondary p-5 card-container" style={{ backgroundColor: '#ffffff' }}>
                {/* {jobData.map((job, index) => (
                    <div key={index} className="Jobs">
                        <div className="JobsCard">
                            <h1 className="JobsCard-Title">{job.job_role}</h1>
                            <h2 class="JobsCard-Image">
                                <i class="fa fa-code fa-3x"></i>
                            </h2>
                            <div class="JobsCard-JobDescription">
                                <p>Location: {job.job_location}</p>
                                <p>Type: {job.job_type}</p>
                            </div>
                            <div class="Button" onClick={sendMail}>Apply</div>
                        </div>
                    </div>
                ))} */}
                <section class="showcase f" style={{ fontFamily: 'sans-serif' }}>

                    <div class="content">
                        <h1> We help you find IT Jobs in Toronto</h1>
                        <div class="row">
                            <div class="col-sm-2"></div>
                            <div class="col-sm-8">
                                <p class="lead text-center">
                                    We’ve helped hundreds of IT professionals and job seekers take the next step in their career, and we’d love to do the same for you. View our available IT jobs in Canada below, or submit your resume for future consideration?
                                </p>
                            </div>
                            <h2>Search for IT Jobs Near You</h2>
                            <div class="col-sm-2"></div>
                        </div>
                        <div className='p-2'></div>
                        <a className="btn btn-danger btn-block rounded-pill" type="submit"  href="./Job_list">View All Jobs</a>
                    </div>
                </section>
            </div>
        </div>
        //</div>
    );
}

export default Jobs_search;