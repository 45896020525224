import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import { useAuth } from '../context/AuthContext';
import axiosInstance from '../context/axios-interceptor.js';

function Login() {
  
  const [formValid, setFormValid] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate()
  const { login } = useAuth();

  useEffect(() => {
  }, [navigate])


  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (formValid) {
      GetUser(username, password);
    }
  };

  useEffect(() => {
    if (username && password) {
      setFormValid(true);
    } else {
      setFormValid(false);
    }
  }, [username, password]);

  async function Encrypt(password) {
    const key = CryptoJS.enc.Utf8.parse('GHYZXFZH2A4B6N6P7R9SPNMT');
    const encrypted = CryptoJS.TripleDES.encrypt(password, key, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    });
    return encrypted.toString();
  }

  async function GetUser(username, password) {
    const encryptedPassword = await Encrypt(password);

    const login_result = await axiosInstance.post("postlogin", {
      user_name: username,
      password: encryptedPassword
    });

    if (login_result.data.status) {
      alert(login_result.data.message);
      console.log(login_result);
      const id = login_result.data.user_id;
      login(username, id);
      navigate('/Add_job_posting')
    } else {
      alert(login_result.data.message);
    }

  }

  return (
    <div className="container-fluid">
      <div className="d-flex flex-column min-vh-100 justify-content-center align-items-center">
        <div className="border border-secondary rounded bg w-25">
          <h1 className="display-5 text-center text-primary ">DataEaver</h1>
          <hr />
          <h4 className="text-center"><small>Login to DataEaver Solutions</small></h4>
          <br />
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-sm-1">
              </div>
              <div className="col-sm-10">
                <input type="text" value={username} className="form-control" placeholder="Username" pattern=".{5,64}" required title="5 to 64 characters minimum" onChange={handleUsernameChange} />
              </div>
              <div className="col-sm-1">
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-sm-1">
              </div>
              <div className="col-sm-10">
                <input type="password" value={password} className="form-control" placeholder="Password" pattern=".{5,64}" required title="5 to 64 characters minimum" onChange={handlePasswordChange} />
              </div>
              <div className="col-sm-1">
              </div>
            </div>
            {/* <div className="row">
              <div className="col-sm-11">
                <div className="float-end">
                  <a className="nav-link"><small>Forgot Password?</small></a>
                </div>
              </div>
              <div className="col-sm-1">
              </div>
            </div> */}
            {/* <div className="row">
              <div className="col-sm-1">
              </div>
              <div className="col-sm-10">
                <div className="form-check">
                  <input type="checkbox" className="form-check-input" id="rememberchekbox" name="option2" value="something" />
                  <label className="form-check-label" htmlFor="rememberchekbox"><small>Remember Me</small></label>
                </div>
              </div>
              <div className="col-sm-1">
              </div>
            </div> */}

            <br />
            <div className="row">
              <label className="text-center text-danger"></label>
            </div>
            <div className="row">
              <div className="col-sm-1">
              </div>
              <div className="col-sm-10">
                <div className="d-grid">
                  <button className="btn btn-primary btn-block" type="submit">Log In</button>
                </div>
              </div>
              <div className="col-sm-1">
              </div>
            </div>
            <br />
            <br />
          </form>
        </div>
      </div>
    </div>
  );
}

export default Login;
