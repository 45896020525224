import axios from 'axios';
//The timeout option in an Axios instance specifies the amount of time in milliseconds that the request should 
//wait for a response from the server before timing out.
// const instance = axios.create({
//   baseURL: 'http://35.163.158.100:8080/',
//   timeout: 500000,
// });
const instance = axios.create({
  baseURL: 'http://localhost:5001/',
  timeout: 500000,
});

// const instance = axios.create({
//   baseURL: 'http://50.87.222.185:5001/',
//   timeout: 500000,
// });

export default instance;
