import React from "react";
import Header from "./Header";
import Services from "./Services"
import Solutions from "./Solutions"
import '../css/style.css';
import Jobs_search from "./Job_search";
import Footer from "./Footer";
import About from "./About";
import Contact from "./Contact";
import '../css/custom.css';

function Home() {
  return (
    <div>
      <Header></Header>
      <section id="Home" class="container-fluid p-5" style={{ marginRight: '10em' }}>
        <div class="row">
            <div class="p-5"></div>
            <div class="p-5"></div>
            <div class="container">
                <div class="display-1">
                    <h1 class="apple">At DataEaver,</h1>
                </div>
            </div>
            <div class="container">
                  <h1 class="display-6 text-secondary">We are dedicated to empowering organizations across diverse
                    domains, including Ecommerce, Manufacturing, Government, and Finance, by weaving data into the
                    fabric of their operations. </h1>
            </div>
              <p class="lead text-secondary ">Our mission is to be the IT Consulting firm of choice, helping
                businesses build and manage robust IT platforms that enable them to derive actionable insights,
                thereby elevating their performance and propelling them towards a brighter future. </p>
        </div>
    </section>
    <section id="Services">
        <Services />
      </section>
      <section id="Solutions">
        <Solutions />
      </section>
      {/* <section id="Job_search">
        <Jobs_search />
      </section> */}
      <section id="About">
        <About />
      </section>
      <section id="Contact">
        <Contact />
      </section>
      <Footer></Footer>
    </div>
  );
}

export default Home;