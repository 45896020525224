import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import Header from "./Header";
import Footer from "./Footer";
import '../css/jobapplicationlist.css';
import axiosInstance from '../context/axios-interceptor.js';

function Job_list() {
    //jobdata
    const [jobData, setJobData] = useState([]);
    const [filteredJobs, setFilteredJobs] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await axiosInstance.get('jobdata'); // Replace with your API endpoint
                if (response.status === 200) {
                    setJobData(response.data.data);
                    setFilteredJobs(response.data.data);
                } else {
                    console.error('Failed to fetch data');
                }
            } catch (error) {
                console.error('Error fetching job data:', error);
            }
        }

        fetchData();
    }, []);
    //jobdata

    const navigate = useNavigate();

    useEffect(() => {
    }, [navigate])

    const handleSearch = (event) => {
        const searchTerm = event.target.value.toLowerCase();
        setSearchTerm(searchTerm);
        const filtered = jobData.filter(job => job.job_role.toLowerCase().includes(searchTerm));
        setFilteredJobs(filtered);
    };
    return (
        <div>
            <Header></Header>
            <div className="p-5"></div>
            <h1 className="display-1 text-center">Discover your new career</h1>
            <div className="row">
                <div className='col-sm-1'>
               
                </div>
                <div className='col-sm-10'>
                <input
                        type="text"
                        placeholder="Search jobs..."
                        value={searchTerm}
                        onChange={handleSearch}
                        className='form-control'                    />
                </div>

            </div>
            <div className="row mb-5">
                <div className='col-sm-12 ms-5 me-5'>
                    <div className="containerjj">
                        {filteredJobs.map((job, index) => (
                            <Link key={index} className="card3" to={`/Job_detail/${job.job_posting_id}`}>
                                <h3>{job.job_role}</h3>
                                <p className="small mt-2">
                                    <p>Location: {job.job_location}</p>
                                    <p>Type: {job.job_type}</p>
                                </p>
                                <div className="dimmer"></div>
                                <div className="go-corner" href="#">
                                    <div className="go-arrow">
                                        →
                                    </div>
                                </div>
                            </Link>
                        ))}
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </div>
    );

}

export default Job_list;

