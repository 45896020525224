import React, { useEffect, useState } from 'react';
import Header from "./Header";
import Footer from "./Footer";
import { useParams } from 'react-router-dom';
import axiosInstance from '../context/axios-interceptor.js';

const Job_detail = () => {
  const { job_posting_id } = useParams();

  const [jobdata_detail, setjobdata_detail] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axiosInstance.get(`jobdata_detail/${job_posting_id}`);
        if (response.status === 200) {
          setjobdata_detail(response.data.data);
        } else {
          console.error('Failed to fetch data');
        }
      } catch (error) {
        console.error('Error fetching job data:', error);
      }
    }

    fetchData();
  }, [job_posting_id]);

  const handleEmailShare = () => {
    const email = 'Enter Your Friend Email';
    const subject = 'Job Opportunity';
    const body = `Check out this job: ${shareUrl}`;
    window.location.href = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
  };

  const shareUrl = window.location.href; // Get the current URL

  return (
    <div>
      <Header />
      <div className='p-5'></div>
      <div className="container mt-5 mb-5">
        <div className="row">
          <div className="col-md-8 offset-md-2">
            {jobdata_detail.map((job) => (
              <div key={job.job_posting_id}>
                <h1 className="font-weight-bold text-center">{job.job_role}</h1>
                <div className="mb-5 d-flex justify-content-center">
                  <a className="btn btn-dark me-2" id="interestedBtn" href={`/Job_application/${job.job_posting_id}`}>I'm Interested</a>
                  <button className="btn btn-outline-dark" id="referralBtn" onClick={handleEmailShare}>Refer a friend</button>

                </div>
                <div className="row">
                  <div className="col-md-8 border">
                    <div className='mt-5'></div>
                    <p><strong>Job Description:</strong> {job.job_description}</p>
                    <p><strong>Job Requirements:</strong> ## Software Developer Position

                      We are seeking a highly skilled and motivated Software Developer to join our dynamic team. As a Software Developer, you will be responsible for designing, developing, and maintaining software applications. The ideal candidate should have a strong understanding of software development methodologies, excellent problem-solving skills, and a passion for creating innovative solutions.

                      ### Responsibilities:

                      - Collaborate with cross-functional teams to analyze, design, and ship new features.
                      - Develop high-quality software that meets coding and design standards.
                      - Debug, optimize, and maintain existing software code.
                      - Participate in code and design reviews to maintain code quality.
                      - Stay up-to-date with industry trends and incorporate best practices into our projects.
                      - Contribute to the entire software development lifecycle.

                      ### Requirements:

                      - Bachelor's degree in Computer Science, Engineering, or a related field.
                      - Proven experience as a Software Developer with a strong portfolio of past projects.
                      - Proficient in one or more programming languages such as Java, Python, C++, or JavaScript.
                      - Experience with web development frameworks (e.g., React, Angular, Vue.js) is a plus.
                      - Familiarity with database systems (e.g., MySQL, PostgreSQL, MongoDB).
                      - Strong problem-solving and analytical skills.
                      - Excellent communication and teamwork skills.
                      - Ability to work in a fast-paced and collaborative environment.
                      - Familiarity with Agile/Scrum development methodologies.
                      - Demonstrated ability to learn and adapt to new technologies.

                      ### How to Apply:

                      If you are passionate about software development and eager to contribute to innovative projects, please submit your resume and portfolio to [your@email.com]. In your application, include a brief cover letter highlighting your relevant experience and explaining why you are the ideal candidate for this position.

                      [Company Name] is an equal opportunity employer. We encourage applications from candidates of all backgrounds and experiences.
                    </p>
                    {/* Add other job content here */}
                  </div>
                  <div className="col-md-4 border">
                    <div className='mt-5'></div>
                    <p><strong>Category:</strong> {job.job_category}</p>
                    <p><strong>Type:</strong> {job.job_type}</p>
                    <p><strong>Location:</strong> {job.job_location}</p>
                    {/* Add other job information here */}
                  </div>
                </div>
                <div className="mb-5 d-flex justify-content-start mt-3">
                  <a className="btn btn-dark me-2" id="interestedBtn" href={`/Job_application/${job.job_posting_id}`}>I'm Interested</a>
                </div>
                <div className="mt-5">
                  <p>Share this job: </p>
                  {/* Add URL share button */}
                  <button
                    className="btn btn-outline-dark me-2"
                    onClick={() => {
                      navigator.clipboard.writeText(shareUrl);
                      alert('Copied to clipboard!');
                    }}
                  >
                    Copy URL
                  </button>
                  <a
                    className="btn btn-outline-dark"
                    id="emailBtn"
                    onClick={handleEmailShare}
                  >
                    Email
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Job_detail;
