import React, { useEffect } from "react";
import { Navbar, Nav } from 'react-bootstrap';
// import Exocube from '../images/logo/Exocube.png'
import { Link, useLocation } from "react-router-dom";
import '../scss/style.css'
import { useAuth } from '../context/AuthContext.js';
import { useNavigate } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';
// import Login from "./Login";


const Header = () => {
    let location = useLocation();
    console.log(location.pathname);
    const { id, logout } = useAuth();
    const navigate = useNavigate();

    const handleLogout = () => {
        secureLocalStorage.removeItem('id');
        secureLocalStorage.removeItem('username');
        logout();
        navigate('/Login');
    };

    useEffect(() => {
        if (id === '') {
            navigate('/Login')
        }
        else {
            console.log(id);
        }
    }, [id]);

    return (
        <body data-bs-spy="scroll" data-bs-target=".navbar" data-bs-offset="50">
            <nav className="navbar navbar-expand-sm bg-dark navbar-dark fixed-top">
                <div class="container-fluid">
                    <div class="navbar-header">
                        <span class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#mynavbar">
                            <span class="navbar-toggler-icon"></span>
                        </span>
                        <a className={location.pathname == "/Add_job_posting" || location.pathname == "/Add_job_posting" ? "nav-item" : "nav-item"}>
                        <Link to="/Add_job_posting" className="nav-link"><a className="navbar-brand" style={{ width: "100px" }}>DataEaver</a></Link> 
                    </a>
                        {/* <a class="navbar-brand" href="/Home">DataEaver</a> */}
                    </div>
                    <ul class="navbar-nav">
                        <li className={location.pathname == "/Add_job_posting" ? "nav-item" : "nav-item"}>
                            <Link to="/Add_job_posting" className={location.pathname == "/Add_job_posting" ? "nav-link active" : "nav-link"}>Add Job</Link>
                        </li>
                        <li className={location.pathname == "/Job_Application_Detail" ? "nav-item" : "nav-item"}>
                            <Link to="/Job_Application_Detail" className={location.pathname == "/Job_Application_Detail" ? "nav-link active" : "nav-link"}>Job Application</Link>
                        </li>
                        {/* <li class="nav-item">
                            <a class="nav-link" href="./Add_job_posting">Add Job Josting</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="./Job_Application_Detail">Job Application</a>
                        </li> */}
                        <li class="nav-item">
                            <a class="nav-link" onClick={handleLogout}>Logout</a>
                        </li>
                    </ul>
                </div>
                {/* <div className="container-fluid">
                    <a className={location.pathname == "/Home" || location.pathname == "/Home" ? "nav-item" : "nav-item"}>
                        <Link to="/Home" className="nav-link"><a className="navbar-brand" style={{ width: "100px" }}>DataEaver</a></Link> 
                    </a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#collapsibleNavbar">
                        <span className="navbar-toggler-icon">
                        </span>
                    </button>
                    <div>
                        <ul className="navbar-nav">
                            <li className={location.pathname == "/Services" ? "nav-item" : "nav-item"}>
                                <Link to="/Services" className={location.pathname == "/Services" ? "nav-link active" : "nav-link"}>Services</Link>
                            </li>
                            <li className={location.pathname == "/Solutions" ? "nav-item " : "nav-item"}>
                                <Link to="/Solutions" className={location.pathname == "/Solutions" ? "nav-link active" : "nav-link"}>Solutions</Link>
                            </li>
                            <li className={location.pathname == "/Job_search" ? "nav-item " : "nav-item"}>
                                <Link to="/Job_search" className={location.pathname == "/Job_search" ? "nav-link active" : "nav-link"}>Jobs Search</Link>
                            </li>
                            <li className={location.pathname == "/About" ? "nav-item " : "nav-item"}>
                                <Link to="/About" className={location.pathname == "/About" ? "nav-link active" : "nav-link"}>About</Link>
                            </li>
                            <li className={location.pathname == "/Contact" ? "nav-item " : "nav-item"}>
                                <Link to="/Contact" className={location.pathname == "/Contact" ? "nav-link active" : "nav-link"} >Contact</Link>
                            </li>
                        </ul>

                    </div>
                </div> */}
            </nav >
            <div className="p-2"></div>
        </body >
    );
}
export default Header;