import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import Header from "./Header";
import Footer from "./Footer";
import '../css/jobapplicationlist.css';
import axiosInstance from '../context/axios-interceptor.js';

function Job_application() {
    const { job_posting_id } = useParams();

    const [jobdata_detail, setjobdata_detail] = useState([]);

    const [formValid, setFormValid] = useState(false);

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await axiosInstance.get(`jobdata_detail/${job_posting_id}`);
                if (response.status === 200) {
                    setjobdata_detail(response.data.data);
                } else {
                    console.error('Failed to fetch data')
                }
            } catch (error) {
                console.error('Error fetching job data:', error);
            }
        } { }

        fetchData();
    }, [job_posting_id]);

    const navigate = useNavigate();

    useEffect(() => {
    }, [navigate])

    const [firstName, setfirstName] = useState("");
    const [lastName, setlastName] = useState("");
    const [email, setemail] = useState("");
    const [phone, setphone] = useState("");
    const [resume, setresume] = useState(null);

    const clearFields = () => {
        setfirstName("");
        setlastName("");
        setemail("");
        setphone("");
        setresume("");
    };

    // const handleSubmit = (event) => {
    //     event.preventDefault();
    // }

    useEffect(() => {
        if (firstName && lastName && email && phone && resume) {
            setFormValid(true);
        } else {
            setFormValid(false);
        }
    }, [firstName, lastName, email, phone, resume]);

    const handleSubmit = (event) => {
        event.preventDefault();
        Job_application_apply(event);
    };

    async function Job_application_apply(event) {
        event.preventDefault();
        const formData = new FormData();
        formData.append('firstName', firstName);
        formData.append('lastName', lastName);
        formData.append('email', email);
        formData.append('phone', phone);
        formData.append('resume', resume);
        formData.append('jobid', job_posting_id);

        //const secretKey = 'GHYZXFZH2A4B6N6P7R9SPNMT';
        const job_result = await axiosInstance.post("insert_job_application_apply", formData, {
            //firstName: firstName,
            //lastName: lastName,
            //email: email,
            //phone: phone,
            //resume: formData,
            //jobid: job_posting_id,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });

        if (job_result.data.status) {
            alert(job_result.data.message);
            clearFields();
        } else {
            alert(job_result.data.message);
        }
    }

    return (
        <div>
            <Header></Header>

            <div className="p-2"></div>
            <div className="row p-5">
                
            <div className="col-sm-3"></div>
                <div className="col-sm-6">
                    <div class="container bg-light border">
                        <div>
                            <div className="text-center">
                                {jobdata_detail.map((job) => (
                                    <h1 className="display-6">{job.job_role} Application</h1>
                                ))}
                            </div>
                            <hr></hr>
                            <form onSubmit={handleSubmit}>
                                <div className='row mb-2'>
                                    {jobdata_detail.map((job) => (
                                        <div key={job.job_posting_id} class="col-sm-6">
                                            <label for="JobName" class="form-label">Job Category</label>
                                            <input type="text" class="form-control" readOnly value={job.job_category} />
                                        </div>
                                    ))}
                                    <div class="col-sm-6 text-end">
                                        {/* <label for="linkedinApply" class="form-label">Apply on LinkedIn</label> */}
                                        <a href="https://www.linkedin.com/" target="_blank" class="btn btn-primary">Apply on LinkedIn</a>
                                    </div>
                                </div>

                                <div className='row mb-2'>
                                    <div class="col-sm-6">
                                        <label for="firstName" class="form-label">First Name *</label>
                                        <input type="text" class="form-control" id="firstName" required value={firstName} onChange={(event) => { setfirstName(event.target.value); }} />
                                    </div>
                                    <div class="col-sm-6">
                                        <label for="lastName" class="form-label">Last Name *</label>
                                        <input type="text" class="form-control" id="lastName" required value={lastName} onChange={(event) => { setlastName(event.target.value); }} />
                                    </div>
                                </div>
                                <div className='row mb-2'>
                                    <div class="col-sm-6">
                                        <label for="email" class="form-label">Email *</label>
                                        <input type="email" class="form-control" id="email" required value={email} onChange={(event) => { setemail(event.target.value); }} />
                                    </div>
                                    <div class="col-sm-6">
                                        <label for="phone" class="form-label">Phone *</label>
                                        <input type="tel" class="form-control" id="phone" required value={phone} onChange={(event) => { setphone(event.target.value); }} />
                                    </div>
                                </div>
                                <div className='row mb-2'>
                                    <div class="col-sm-12">
                                        <label for="resume" class="form-label">Resume/CV *</label>
                                        <input type="file" class="form-control" id="resume" accept=".pdf, .doc, .docx, .txt, .rtf" onChange={(event) => { setresume(event.target.files[0]); }} required />
                                    </div>
                                </div>
                                <div className='row mb-2'>
                                    <div className="col-sm-12 text-end">
                                        <button type="clear" className="btn btn-primary me-2" onClick={clearFields}>Clear</button>

                                        <button type="submit" className="btn btn-primary">Submit</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="col-sm-3"></div>
            </div>
            <Footer></Footer>
        </div>
    );

}

export default Job_application;

