import React from "react";
import '../css/cardbg.css';
import '../css/card.css';
import '../js/card.js';

function Services() {
    return (
        <div>
            <div id="Services" class="container-fluid p-5 gradientanimation">
                <div class="carddivision">
                    <div class="divsection">
                        <div class="cardcolumn divcard-style">
                            <div class="divcard-text text-white">
                                <div class="cardH1">DevOps Excellence</div>
                                <hr />
                                <div class="cardp ellipsis" style={{ fontFamily: 'sans-serif' }}>
                                    <p>Streamline development and
                                        operations for faster, more reliable
                                        software
                                        delivery.</p>
                                    <p>Implement automation and
                                        continuous integration/continuous delivery
                                        (CI/CD)
                                        pipelines.</p>
                                    <p>Enhance collaboration
                                        between development and IT operations teams.</p>
                                </div>
                            </div>
                        </div>
                        <div class="cardcolumn divcard-style">
                            <div class="divcard-text text-white">
                                <div class="cardH1">Data Analytics Mastery</div>
                                <hr />
                                <div class="cardp ellipsis" style={{ fontFamily: 'sans-serif' }}>
                                    <p>Transform raw data into
                                        valuable insights.</p>
                                    <p>Develop custom data
                                        analytics solutions for informed decision-making.
                                    </p>
                                    <p> Implement data
                                        visualization tools for clear reporting.</p>
                                </div>
                            </div>
                        </div>
                        <div class="cardcolumn divcard-style">
                            <div class="divcard-text text-white">
                                <div class="cardH1">Information Security Assurance</div>
                                <hr />
                                <div class="cardp ellipsis" style={{ fontFamily: 'sans-serif' }}>
                                    <p>Protect your valuable data
                                        and assets from cyber threats.</p>
                                    <p>Conduct security
                                        assessments, vulnerability testing, and risk
                                        analysis.</p>
                                    <p> Develop and implement
                                        robust information security strategies.</p>
                                </div>
                            </div>
                        </div>
                        <div class="cardcolumn divcard-style">
                            <div class="divcard-text text-white">
                                <div class="cardH1">DevSecOps Integration</div>
                                <hr />
                                <div class="cardp ellipsis" style={{ fontFamily: 'sans-serif' }}>
                                    <p>Infuse security into the
                                        software development process from the outset.
                                    </p>
                                    <p>Automate security testing
                                        and compliance checks.</p>
                                    <p> Strengthen your
                                        organization's security posture.</p>
                                </div>
                            </div>
                        </div>
                        <div class="cardcolumn divcard-style">
                            <div class="divcard-text text-white">
                                <div class="cardH1">Big Data Solutions</div>
                                <hr />
                                <div class="cardp ellipsis" style={{ fontFamily: 'sans-serif' }}>
                                    <p>Handle and analyze massive
                                        volumes of data efficiently.</p>
                                    <p> Build scalable,
                                        data-driven applications.</p>
                                    <p>Unlock the potential of
                                        big data for business insights.</p>
                                </div>
                            </div>
                        </div>
                        <div class="cardcolumn divcard-style">
                            <div class="divcard-text text-white">
                                <div class="cardH1">Infra structure Support</div>
                                <hr />
                                <div class="cardp ellipsis" style={{ fontFamily: 'sans-serif' }}>
                                    <p>Ensure the stability and
                                        performance of your IT infrastructure.</p>
                                    <p>Provide 24/7 monitoring
                                        and support.</p>
                                    <p> Optimize infrastructure
                                        for cost-efficiency.</p>
                                </div>
                            </div>
                        </div>
                        <div class="cardcolumn divcard-style">
                            <div class="divcard-text text-white">
                                <div class="cardH1 ">L1 to L3 Support Services</div>
                                <hr />
                                <div class="cardp ellipsis" style={{ fontFamily: 'sans-serif' }}>
                                    <p>Comprehensive technical support
                                        across all levels.</p>
                                    <p>Rapid issue resolution and
                                        escalation management.</p>
                                    <p>Proactive maintenance to prevent
                                        downtime.</p>
                                </div>
                            </div>
                        </div>
                        <div class="cardcolumn divcard-style">
                            <div class="divcard-text text-white">
                                <div class="cardH1">Operations Enhancement</div>
                                <hr />
                                <div class="cardp ellipsis" style={{ fontFamily: 'sans-serif' }}>
                                    <p>Optimize IT operations for maximum
                                        efficiency.</p>
                                    <p>Implement ITIL best practices.</p>
                                    <p>Reduce operational costs and enhance
                                        service quality.</p>
                                </div>
                            </div>
                        </div>
                        <div class="cardcolumn divcard-style">
                            <div class="divcard-text text-white ">
                                <div class="cardH1">Application Development</div>
                                <hr />
                                <div class="cardp ellipsis" style={{ fontFamily: 'sans-serif' }}>
                                    <p>Design and develop custom software
                                        solutions tailored to your business
                                        needs.
                                    </p>
                                    <p> Modernize legacy applications for
                                        improved performance and user
                                        experience.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="cardcolumn divcard-style">
                            <div class="divcard-text text-white">
                                <div class="cardH1">Mobile App Development</div>
                                <hr />
                                <div class="cardp ellipsis" style={{ fontFamily: 'sans-serif' }}>
                                    <p>Create engaging and user-friendly
                                        mobile applications.</p>
                                    <p>Support for iOS, Android, and
                                        cross-platform development</p>
                                    <p> Mobile app strategy and design
                                        consulting.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default Services;