import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import '../css/slider.css';
import '../js/slider.js';

function Solutions() {
    const [activeIndex, setActiveIndex] = useState(0);
    const navigate = useNavigate();

    useEffect(() => {
        // Scroll to the top of the page when the component mounts
        window.scrollTo(0, 0);

        // Navigate to the home page ("/") when the component mounts or when the page is refreshed
        navigate('/');

        const handleScroll = () => {
            const sections = document.querySelectorAll("section[id]");
            const scrollY = window.scrollY;

            sections.forEach((section, index) => {
                const sectionTop = section.offsetTop;
                const sectionHeight = section.offsetHeight;

                if (scrollY >= sectionTop - 50 && scrollY <= sectionTop + sectionHeight - 50) {
                    setActiveIndex(index);
                }
            });
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [navigate]);

    const handleIndicatorClick = (index) => {
        setActiveIndex(index);
        // Optionally, you can scroll to the corresponding section here
    };

    const handleNext = () => {
        setActiveIndex((prevIndex) => (prevIndex === 3 ? 0 : prevIndex + 1));
    };

    const handlePrev = () => {
        setActiveIndex((prevIndex) => (prevIndex === 0 ? 3 : prevIndex - 1));
    };


    return (
        <div>
            <div id="Solutions" className="carousel slide" data-bs-ride="carousel" data-bs-interval="2000">
                <div className="carousel-indicators">
                    {[...Array(4)].map((_, index) => (
                        <button
                            key={index}
                            type="button"
                            data-bs-target="#Solutions"
                            data-bs-slide-to={index}
                            className={index === activeIndex ? "active" : ""}
                            onClick={() => handleIndicatorClick(index)}
                        ></button>
                    ))}
                </div>

                <div className="carousel-inner">
                    <div className={`carousel-item ${activeIndex === 0 ? "active" : ""}`}>
                        <div className="aslider">
                            <div className="boxmg1 boxmg">
                                <div className="bgmg"></div>
                                <div className="detailsmg text-white">
                                    <h1>Consulting</h1>
                                    <p>We offer expert guidance and advisory services to help you make informed technology decisions.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`carousel-item ${activeIndex === 1 ? "active" : ""}`}>
                        <div className="aslider">
                            <div className="boxmg2 boxmg">
                                <div className="bgmg"></div>
                                <div className="detailsmg text-white">
                                    <h1>Solution Provider</h1>
                                    <p>We deliver end-to-end solutions, from concept to implementation, tailored to your unique requirements.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`carousel-item ${activeIndex === 2 ? "active" : ""}`}>
                        <div className="aslider">
                            <div className="boxmg3 boxmg">
                                <div className="bgmg"></div>
                                <div className="detailsmg text-white">
                                    <h1>IT Staffing</h1>
                                    <p>We provide skilled IT professionals to supplement your team and drive project success.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`carousel-item ${activeIndex === 3 ? "active" : ""}`}>
                        <div className="aslider">
                            <div className="boxmg4 boxmg">
                                <div className="bgmg"></div>
                                <div className="detailsmg text-white">
                                    <h1>At DataEaver</h1>
                                    <p>We are partners in your digital transformation journey, dedicated to helping you achieve success.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <button className="carousel-control-prev" type="button" data-bs-target="#Solutions" data-bs-slide="prev" onClick={handlePrev}>
                    <span className="carousel-control-prev-icon"></span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#Solutions" data-bs-slide="next" onClick={handleNext}>
                    <span className="carousel-control-next-icon"></span>
                </button>
            </div>
        </div>
    );
}

export default Solutions;
