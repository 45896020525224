import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'font-awesome/css/font-awesome.min.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './components/Home';
import Services from './components/Services';
import Solutions from './components/Solutions';
import Job_search from './components/Job_search';
import About from './components/About';
import Contact from './components/Contact';
import Job_application from './components/Job_application';
import Login from './adminpanel/Login';
import Add_job_posting from './adminpanel/Add_job_posting';
import JobDetail from './components/Job_detail';
import Job_list from './components/Job_list';
import ResumeUpload from './components/ResumeUpload';
import Job_Application_Detail from './adminpanel/Job_Application_Detail';
import PdfViewer from './adminpanel/PdfViewer';
import { AuthProvider } from './context/AuthContext';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <AuthProvider>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/services" element={<Services />} /> 
            <Route path="/solutions" element={<Solutions />} /> 
            <Route path="/job_search" element={<Job_search />} /> 
            <Route path="/about" element={<About />} /> 
            <Route path="/contact" element={<Contact />} />          
            <Route path="/job_application/:job_posting_id" element={<Job_application />} />      
            <Route path="/job_list" element={<Job_list />} />
            <Route path="/job_detail/:job_posting_id" element={<JobDetail/>} />
            <Route path="/resume_upload" element={<ResumeUpload/>} />
            <Route path="/login" element={<Login />} />
            <Route path="/add_job_posting" element={<Add_job_posting />} />
            <Route path="/job_application_detail" element={<Job_Application_Detail />} />
            <Route path="/pdf_viewer" element={<PdfViewer />} />
          </Routes>
        </AuthProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
