import React, { createContext, useContext, useState, useEffect } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [username, setUsername] = useState(() => localStorage.getItem('username') || '');
  const [id, setId] = useState(() => localStorage.getItem('id') || '');

  const login = (username, id) => {
    // Perform login logic, set username in the state and localStorage
    setUsername(username);
    setId(id);

    localStorage.setItem('username', username);
    localStorage.setItem('id', id);
  };

  const logout = () => {
    // Perform logout logic, reset username in the state and localStorage
    setUsername('');
    setId('');

    localStorage.removeItem('username');
    localStorage.removeItem('id');
  };

  // Use useEffect to check localStorage on component mount
  useEffect(() => {
    const storedUsername = localStorage.getItem('username');
    const storedId = localStorage.getItem('id');
    if (storedUsername && storedId ) {
      setUsername(storedUsername);
      setId(storedId);
    }
  }, []);

  return (
    <AuthContext.Provider value={{ username, id, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};



export const useAuth = () => {
  return useContext(AuthContext);
};
