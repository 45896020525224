import React from "react";
import '../css/footer.css';
import Logo from '../img/Dataeaver.png'
function Footer() {
    return (
    <div>
        <footer class="footer-section">
        <div class="container">
            <div class="footer-cta pt-2 pb-4 ps-5">
                <div class="row">
                    <div class="col-xl-4 col-md-4 mb-30 ps-5">
                        <div class="single-cta">
                            <i class="fa fa-map-marker"></i>
                            <div class="cta-text">
                                <h4>Find us</h4>
                                <span>Brampton ON, Canada</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-4 col-md-4 mb-30 ps-5">
                        <div class="single-cta">
                            <i class="fa fa-phone"></i>
                            <div class="cta-text">
                                <h4>Call us</h4>
                                <span>+1 (647) 324-8824</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-4 col-md-4 mb-30 ps-5">
                        <div class="single-cta">
                            <i class="fa fa-envelope-open"></i>
                            <div class="cta-text">
                                <h4>Mail us</h4>
                                <span>dataeaver@gmail.com</span>
                            </div>
                        </div>
                    </div>
                    {/* <div class="col-xl-3 col-md-4 mb-30">
                        <div class="single-cta">
                            <div class="cta-text">
                                <h4>Follow us</h4>
                            </div>
                            <div class="footer-social-icon">
                                <a href="https://facebook.com/" target="_blank"><i class="fa fa-facebook"></i></a>
                                <a href="https://www.linkedin.com/" target="_blank"><i class="fa fa-linkedin"></i></a>
                                <a href="https://twitter.com/" target="_blank"><i class="fa fa-twitter"></i></a>
                                <a href="https://github.com/" target="_blank"><i class="fa fa-github"></i></a>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
            <div class="footer-content pt-5 pb-5 ps-5">
                <div class="row">
                    <div class="col-xl-4 col-lg-4 mb-50">
                        <div class="footer-widget">
                            <div class="footer-logo">
                                <a href="\"><img src={Logo} alt="Exocube"  class="img-fluid"/></a>
                            </div>
                            {/* <div class="footer-text">
                                <p>DataEaver</p>
                            </div> */}
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-6 mb-30">
                        <div class="footer-widget">
                            <div class="footer-widget-heading">
                                <h3>Useful Links</h3>
                            </div>
                            <ul>
                                <li><a href="/">Home</a></li>
                                {/* <li><a href="/#About">about</a></li> */}
                                <li><a href="/#Services">Services</a></li>
                                <li><a href="/#Solutions">Solutions</a></li>
                                {/* <li><a href="/#Job_search">Jobs</a></li> */}
                                <li><a href="/#Contact">Contact us</a></li>
                                <li><a href="/#About">About us</a></li>
                                {/* <li><a href="#">Our Services</a></li>
                                <li><a href="#">Expert Team</a></li>
                                <li><a href="#">Contact us</a></li> */}
                                {/* <li><a href="#">Latest News</a></li> */}
                            </ul>
                        </div>
                    </div>
                    {/* <div class="col-xl-4 col-lg-4 col-md-6 mb-50 pe-5">
                        <div class="footer-widget">
                            <div class="footer-widget-heading">
                                <h3>Subscribe</h3>
                            </div>
                            <div class="footer-text mb-25">
                                <p>Don’t miss to subscribe to our new feeds, kindly fill the form below.</p>
                            </div>
                            <div class="subscribe-form">
                                <form action="#">
                                    <input type="text" placeholder="Email Address"/>
                                    <button><i class="fa fa-send"></i></button>
                                </form>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
        <div class="copyright-area">
            <div class="container">
                <div class="row">
                    <div class="col-sm-12 text-center">
                        <div class="copyright-text">
                            <p>Copyright &copy; 2023 DataEaver Sols.</p>
                        </div>
                    </div>
                    {/* <div class="col-xl-6 col-lg-6 d-none d-lg-block text-right">
                        <div class="footer-menu">
                            <ul>
                                <li><a href="#">Home</a></li>
                                <li><a href="#">Terms</a></li>
                                <li><a href="#">Privacy</a></li>
                                <li><a href="#">Policy</a></li>
                                <li><a href="#">Contact</a></li>
                            </ul>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    </footer>
     </div >
     );
}

export default Footer;